// Generated by ReScript, PLEASE EDIT WITH CARE

import * as P from "../../../../styleguide/components/Paragraph/P.res.js";
import * as Button from "../../../../styleguide/components/Button/Button.res.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as IconBuilding from "../../../../styleguide/icons/IconBuilding.res.js";
import * as Routes_Project from "../../../../routes/common/Routes_Project.res.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as UserColocationRfpProject_Details_Locations_Map from "./UserColocationRfpProject_Details_Locations_Map.res.js";
import * as UserColocationRfpProject_Details_LocationsScss from "./UserColocationRfpProject_Details_Locations.scss";
import * as UserColocationRfpProject_Details_Locations_ProviderDataCentersLocationPanel from "./UserColocationRfpProject_Details_Locations_ProviderDataCentersLocationPanel.res.js";

var css = UserColocationRfpProject_Details_LocationsScss;

function providerTextWithCountDistinction(x) {
  if (x !== 1) {
    return " providers";
  } else {
    return " provider";
  }
}

function getHeadingQuantityTextForUser(userRole, locations, providers) {
  switch (userRole) {
    case "User" :
    case "Agent" :
        break;
    default:
      return "";
  }
  return String(locations.length) + (" from " + (String(providers.length) + providerTextWithCountDistinction(providers.length)));
}

function UserColocationRfpProject_Details_Locations(props) {
  var providers = props.providers;
  var userRole = props.userRole;
  var locations = props.locations;
  var tmp;
  var exit = 0;
  switch (userRole) {
    case "User" :
    case "Agent" :
        exit = 1;
        break;
    default:
      tmp = null;
  }
  if (exit === 1) {
    tmp = JsxRuntime.jsx(Button.AsLink.make, {
          href: Routes_Project.Colocation.$$Location.update(props.projectId),
          size: "SM",
          color: "Teal",
          ghost: true,
          buttonClassName: css.editButton,
          children: "Edit Locations"
        });
  }
  var tmp$1;
  switch (userRole) {
    case "Provider" :
        tmp$1 = "The list of your locations requested by the project owner. ";
        break;
    case "Visitor" :
        tmp$1 = "";
        break;
    case "Admin" :
    case "Concierge" :
        tmp$1 = "The list of locations and providers requested by the project owner. Only locations from providers that have accepted registration are shown.";
        break;
    case "User" :
    case "Agent" :
        tmp$1 = "The list of providers that you requested. Providers may be added or removed at the discretion of Datacenters.com. Only locations from providers that have accepted to participate in this project are shown.";
        break;
    
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsxs("div", {
                      children: [
                        JsxRuntime.jsxs("div", {
                              children: [
                                JsxRuntime.jsx("h2", {
                                      children: "Providers and Locations",
                                      className: css.locationHeading
                                    }),
                                JsxRuntime.jsx("p", {
                                      children: getHeadingQuantityTextForUser(userRole, locations, providers),
                                      className: css.quantityText
                                    })
                              ]
                            }),
                        tmp
                      ],
                      className: css.headingContentWrapper
                    }),
                JsxRuntime.jsx("p", {
                      children: tmp$1,
                      className: css.roleText
                    }),
                providers.length !== 0 ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                        children: [
                          JsxRuntime.jsx(UserColocationRfpProject_Details_Locations_Map.make, {
                                locations: locations,
                                serverSide: props.serverSide
                              }),
                          JsxRuntime.jsx("div", {
                                children: Belt_Array.map(providers, (function (provider) {
                                        return JsxRuntime.jsx(UserColocationRfpProject_Details_Locations_ProviderDataCentersLocationPanel.make, {
                                                    provider: provider
                                                  }, provider.name + "-panel");
                                      })),
                                className: css.providersWrapper
                              })
                        ]
                      }) : JsxRuntime.jsxs("div", {
                        children: [
                          JsxRuntime.jsx(IconBuilding.make, {
                                size: "XXXXXL",
                                color: "Teal"
                              }),
                          JsxRuntime.jsx(P.make, {
                                children: "Providers are still reviewing this project and acceptance is pending."
                              })
                        ],
                        className: css.emptyProviders
                      })
              ]
            });
}

var make = UserColocationRfpProject_Details_Locations;

export {
  css ,
  providerTextWithCountDistinction ,
  getHeadingQuantityTextForUser ,
  make ,
}
/* css Not a pure module */
