// extracted by mini-css-extract-plugin
export var additionalNotes = "UserColocationRfpProject_Details_RequiredProducts__additionalNotes__ocVIk";
export var checkboxes = "UserColocationRfpProject_Details_RequiredProducts__checkboxes__Uuwfw";
export var checkboxesContainer = "UserColocationRfpProject_Details_RequiredProducts__checkboxesContainer__Lctao";
export var column = "UserColocationRfpProject_Details_RequiredProducts__column__Ce2oO";
export var descriptionHeading = "UserColocationRfpProject_Details_RequiredProducts__descriptionHeading__IX38Y";
export var flex = "UserColocationRfpProject_Details_RequiredProducts__flex__Soidp";
export var flexColumn = "UserColocationRfpProject_Details_RequiredProducts__flexColumn__KOujA";
export var gap1 = "UserColocationRfpProject_Details_RequiredProducts__gap1__MxajN";
export var gap2 = "UserColocationRfpProject_Details_RequiredProducts__gap2__RZ2lo";
export var gap3 = "UserColocationRfpProject_Details_RequiredProducts__gap3__rPgEi";
export var gap4 = "UserColocationRfpProject_Details_RequiredProducts__gap4__kxrAG";
export var gap5 = "UserColocationRfpProject_Details_RequiredProducts__gap5__BPBw6";
export var lightBackground = "UserColocationRfpProject_Details_RequiredProducts__lightBackground__O7aGf";
export var notesContainer = "UserColocationRfpProject_Details_RequiredProducts__notesContainer__Lfa1_";
export var panel = "UserColocationRfpProject_Details_RequiredProducts__panel__Vo22F";
export var panelBody = "UserColocationRfpProject_Details_RequiredProducts__panelBody__z1sTz";
export var panelHeading = "UserColocationRfpProject_Details_RequiredProducts__panelHeading__oy6eY";
export var productName = "UserColocationRfpProject_Details_RequiredProducts__productName__lxrWW";
export var productRow = "UserColocationRfpProject_Details_RequiredProducts__productRow__dtTuz";
export var productServiceLabel = "UserColocationRfpProject_Details_RequiredProducts__productServiceLabel__C8Yl6";
export var productServiceValue = "UserColocationRfpProject_Details_RequiredProducts__productServiceValue__Ls_TS";
export var productsServiceWrapper = "UserColocationRfpProject_Details_RequiredProducts__productsServiceWrapper__u4Nbj";
export var propertiesContainer = "UserColocationRfpProject_Details_RequiredProducts__propertiesContainer__G5VNx";
export var propertyLeft = "UserColocationRfpProject_Details_RequiredProducts__propertyLeft__Nhl0N";
export var propertyRight = "UserColocationRfpProject_Details_RequiredProducts__propertyRight__IOCEv";
export var row = "UserColocationRfpProject_Details_RequiredProducts__row__WfESW";
export var stayShaded = "UserColocationRfpProject_Details_RequiredProducts__stayShaded___Qgra";
export var switchLight = "UserColocationRfpProject_Details_RequiredProducts__switchLight__YOWXe";
export var switchShaded = "UserColocationRfpProject_Details_RequiredProducts__switchShaded__c9yYS";
export var tickmarkIcon = "UserColocationRfpProject_Details_RequiredProducts__tickmarkIcon__qx89j";
export var withColon = "UserColocationRfpProject_Details_RequiredProducts__withColon__qR8Xd";