// extracted by mini-css-extract-plugin
export var column = "UserColocationRfpProject__column__bqpDq";
export var container = "UserColocationRfpProject__container__HjEcg";
export var contentWrapper = "UserColocationRfpProject__contentWrapper__SVM4s";
export var flex = "UserColocationRfpProject__flex__CwLof";
export var flexColumn = "UserColocationRfpProject__flexColumn__FtPn6";
export var gap1 = "UserColocationRfpProject__gap1__YJPfR";
export var gap2 = "UserColocationRfpProject__gap2__kWTyu";
export var gap3 = "UserColocationRfpProject__gap3__dezgR";
export var gap4 = "UserColocationRfpProject__gap4__JM7Q4";
export var gap5 = "UserColocationRfpProject__gap5__TT7z3";
export var row = "UserColocationRfpProject__row__AC70j";