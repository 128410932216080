// extracted by mini-css-extract-plugin
export var colLeft = "UserColocationRfpProject_Details__colLeft__hZla6";
export var colRight = "UserColocationRfpProject_Details__colRight__QSf4I";
export var column = "UserColocationRfpProject_Details__column__SQb8u";
export var columnWrapper = "UserColocationRfpProject_Details__columnWrapper__z3xRF";
export var descriptionContent = "UserColocationRfpProject_Details__descriptionContent__GiOwQ";
export var descriptionHeading = "UserColocationRfpProject_Details__descriptionHeading__EbLoH";
export var flex = "UserColocationRfpProject_Details__flex__dsVw7";
export var flexColumn = "UserColocationRfpProject_Details__flexColumn__z9BO5";
export var gap1 = "UserColocationRfpProject_Details__gap1__hPo6d";
export var gap2 = "UserColocationRfpProject_Details__gap2__x6vav";
export var gap3 = "UserColocationRfpProject_Details__gap3__Gmt5X";
export var gap4 = "UserColocationRfpProject_Details__gap4__OBcRp";
export var gap5 = "UserColocationRfpProject_Details__gap5__MeaTl";
export var lastUpdated = "UserColocationRfpProject_Details__lastUpdated__a0XsR";
export var reviewStatusWarning = "UserColocationRfpProject_Details__reviewStatusWarning__wIBlj";
export var row = "UserColocationRfpProject_Details__row__PLnvq";